import { IBook } from '../types/books';
import { ICategory } from '../types/categories';

export const mapBookData = (book: any): IBook => ({
  categories: book.categories.map((category: ICategory) => category._id),
  imageUrl: `${process.env.REACT_APP_GC_URI}/${book.coverURI}`,
  description: book.description,
  favoriteCount: book.favoriteCount,
  pages: book.pages,
  minAge: book.minAge,
  price: book.price,
  readingCount: book.readingCount,
  scrollImg: `${process.env.REACT_APP_GC_URI}/${book.scrollImg}`,
  title: book.title,
  _id: book._id,
  author: book.author,
  pdfUrl: `${process.env.REACT_APP_GC_URI}/${book.bookURI}`,
  isPublished: book.isPublished,
});
