import { FC, useState, ChangeEvent, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';

import InputField from '../../components/InputField/InputField';
import MainButton from '../../components/buttons/MainButton/MainButton';
import CategorySelector from '../../components/CategorySelector/CategorySelector';
import PdfPreview from '../../components/PdfPreview/PdfPreview';
import ImageUploader from '../../components/ImageUploader/ImageUploader';

import { IBook } from '../../types/books';
import { uploadBook } from '../../store/thunks/books';
import { useAppDispatch } from '../../hooks/redux';
import { fetchCategories } from '../../store/thunks/categories';

import styles from './UploadBookPage.module.css';

const UploadBookPage: FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IBook>({
    defaultValues: {
      title: '',
      author: '',
      price: 0,
      pdfUrl: '',
      imageUrl: '',
      categories: [],
      minAge: 0,
    },
  });

  const [image, setImage] = useState<File | null>(null);
  const [pdf, setPdf] = useState<File | null>(null);
  const [pdfName, setPdfName] = useState<string | null>(null);

  const handlePdfChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setPdf(e.target.files[0]);
      setPdfName(e.target.files[0].name);
    }
  };

  const removeFile = (type: 'image' | 'pdf') => {
    if (type === 'image') {
      setImage(null);
    } else if (type === 'pdf') {
      setPdf(null);
      setPdfName(null);
    }
  };

  const onSubmit = (data: IBook) => {
    if (image && pdf) {
      const payload = {
        data,
        files: {
          cover: image,
          book: pdf,
          scrollImg: null,
        },
      };

      dispatch(uploadBook(payload));
    }
  };

  return (
    <div className={styles.bookUpload}>
      <h1>Upload New Book</h1>
      <ImageUploader
        image={image}
        imageUrl={null}
        onImageChange={setImage}
        editMode
        inputId="image"
      />
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={styles.inputsContainer}
      >
        <div className={styles.inputGroup}>
          <label>Title</label>
          <Controller
            name="title"
            control={control}
            rules={{ required: 'Title is required' }}
            render={({ field }) => (
              <InputField
                type="text"
                placeholder="Title"
                {...field}
                error={errors.title?.message}
              />
            )}
          />
        </div>
        <div className={styles.inputGroup}>
          <label>Author</label>
          <Controller
            name="author"
            control={control}
            rules={{ required: 'Author is required' }}
            render={({ field }) => (
              <InputField
                type="text"
                placeholder="Author"
                {...field}
                error={errors.author?.message}
              />
            )}
          />
        </div>
        <div className={styles.inputGroup}>
          <label>Minimal age</label>
          <Controller
            name="minAge"
            control={control}
            rules={{ required: 'Minimal age is required' }}
            render={({ field }) => (
              <InputField
                type="number"
                placeholder="Minimal age"
                {...field}
                error={errors.minAge?.message}
              />
            )}
          />
        </div>
        <div className={styles.inputGroup}>
          <label>Price</label>
          <Controller
            name="price"
            control={control}
            rules={{ required: 'Price is required' }}
            render={({ field }) => (
              <InputField
                type="number"
                placeholder="Price"
                {...field}
                error={errors.price?.message}
              />
            )}
          />
        </div>
        <div className={styles.inputGroup}>
          <label>Categories</label>
          <Controller
            name="categories"
            control={control}
            render={({ field, fieldState }) => (
              <CategorySelector
                field={field}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </div>
        <div className={`${styles.inputGroup} ${styles.bookItem}`}>
          <label>{`Book (PDF)${pdfName ? ` - ${pdfName}` : ''}`}</label>
          <input
            type="file"
            id="pdf-upload"
            onChange={handlePdfChange}
            accept="application/pdf"
            style={{ display: 'none' }}
          />
          <div>
            <MainButton
              label="Attach PDF file"
              onClick={(e) => {
                e.preventDefault();
                document.getElementById('pdf-upload')?.click();
              }}
            />
          </div>
          {pdf && (
            <button
              onClick={() => removeFile('pdf')}
              className={styles.removeButton}
            >
              Remove PDF
            </button>
          )}
        </div>
        {pdf && <PdfPreview file={pdf} />}
        <div className={styles.buttonContainer}>
          <MainButton label="Upload to server" type="submit" />
        </div>
      </form>
    </div>
  );
};

export default UploadBookPage;
