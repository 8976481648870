import React, { FC, useEffect, useState } from 'react';

import BookSelector from '../../components/BookSelector/BookSelector';
import { useAppDispatch } from '../../hooks/redux';
import { fetchBooks } from '../../store/thunks/books';
import PageConstructor from '../../components/PageConstructor/PageConstructor';

const CreateBookPage: FC = () => {
  const dispatch = useAppDispatch();

  const [showEditor, setShowEditor] = useState<boolean>(false);

  useEffect(() => {
    dispatch(fetchBooks());
  }, [dispatch]);

  const handleShowEditor = () => setShowEditor(true);
  const handleHideEditor = () => setShowEditor(false);
  return (
    <div>
      {showEditor ? (
        <PageConstructor onHide={handleHideEditor} />
      ) : (
        <BookSelector onShow={handleShowEditor} />
      )}
    </div>
  );
};

export default CreateBookPage;
