import {
  FC,
  useState,
  // ChangeEvent
} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';

import InputField from '../../components/InputField/InputField';
import MainButton from '../../components/buttons/MainButton/MainButton';
import ResultModal from '../../components/modals/ResultModal/ResultModal';
import CategorySelector from '../../components/CategorySelector/CategorySelector';
import ImageUploader from '../../components/ImageUploader/ImageUploader';
// import PdfPreview from '../../components/PdfPreview/PdfPreview';

import { RootState } from '../../store/store';
import { IBook } from '../../types/books';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { deleteBook, editBook, publishBook } from '../../store/thunks/books';
import { categoriesSelector } from '../../store/selectors/categories';
import { bookByIdSelector } from '../../store/selectors/books';
import { UploadBookPayload } from '../../store/types';

import styles from './BookPage.module.css';
import PageThumbnail from '../../components/PageThumbnail/PageThumbnail';

const BookPage: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { id } = useParams<{ id: string }>();

  const book = useAppSelector((state: RootState) =>
    bookByIdSelector(state, id)
  );
  console.log('🚀 ~ book:', book);

  const categories = useAppSelector(categoriesSelector);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IBook>({
    defaultValues: {
      title: book?.title || '',
      author: book?.author || '',
      description: book?.description || '',
      price: book?.price || 0,
      minAge: book?.minAge || 0,
      categories: book?.categories || [],
      imageUrl: book?.imageUrl || '',
    },
  });

  const [editMode, setEditMode] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);

  const [coverImage, setCoverImage] = useState<File | null>(null);
  const uploadCoverImage = async (file: File | null) => {
    setCoverImage(file);
  };

  const [scrollImage, setScrollImage] = useState<File | null>(null);
  const uploadScrollImg = async (file: File | null) => {
    setScrollImage(file);
  };

  if (!book) {
    return (
      <div className={styles.bookDetails}>
        <p className={styles.bookNotFound}>Book not found</p>
      </div>
    );
  }

  const onSubmit = async (data: IBook) => {
    const updatedBook: UploadBookPayload = {
      data: {
        ...data,
        _id: book._id,
      },
      files: {
        cover: coverImage,
        scrollImg: scrollImage,
      },
    };

    try {
      await dispatch(editBook(updatedBook)).unwrap();
      // navigate('/dashboard/books');
    } catch (error) {
      console.error('Error editing book:', error);
    }
  };

  const handleDelete = () => {
    if (book._id) {
      dispatch(deleteBook(book._id)).then(() => {
        navigate('/dashboard/books');
      });
    }
  };

  const handlePageClick = (
    htmlContent: string,
    cssContent: string,
    width: number
  ) => {
    const newWindow = window.open('', '_blank', `width=${width},height=800`);
    if (newWindow) {
      const document = newWindow.document;
      document.write(`
        <html>
          <head>
            <style>${cssContent}</style>
          </head>
          <body>${htmlContent}</body>
        </html>
      `);
      document.close();
    }
  };

  const handlePublish = async () => {
    if (book._id) {
      dispatch(publishBook(book._id));
      // .then(() => {
      //   navigate('/dashboard/books');
      // });
    }
  };

  return (
    <div className={styles.bookDetails}>
      <h1>Book Details</h1>
      <div className={styles.bookImages}>
        <div className={styles.bookImage}>
          <h4>Cover Image</h4>
          <ImageUploader
            image={coverImage}
            imageUrl={typeof book.imageUrl === 'string' ? book.imageUrl : null}
            onImageChange={uploadCoverImage}
            editMode={editMode}
            inputId="coverImage"
          />
        </div>
        <div className={styles.bookImage}>
          <h4>Scroll Image</h4>
          <ImageUploader
            image={scrollImage}
            imageUrl={
              typeof book.scrollImg === 'string' ? book.scrollImg : null
            }
            onImageChange={uploadScrollImg}
            editMode={editMode}
            inputId="scrollImage"
          />
        </div>
      </div>
      {editMode ? (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={styles.inputsContainer}
        >
          <div className={styles.inputGroup}>
            <label>Title</label>
            <Controller
              name="title"
              control={control}
              rules={{ required: 'Title is required' }}
              render={({ field }) => (
                <InputField
                  type="text"
                  placeholder="Title"
                  {...field}
                  error={errors.title ? errors.title.message : ''}
                  value={field.value}
                />
              )}
            />
          </div>
          <div className={styles.inputGroup}>
            <label>Author</label>
            <Controller
              name="author"
              control={control}
              rules={{ required: 'Author is required' }}
              render={({ field }) => (
                <InputField
                  type="text"
                  placeholder="Author"
                  {...field}
                  error={errors.author ? errors.author.message : ''}
                  value={field.value}
                />
              )}
            />
          </div>
          <div className={styles.inputGroup}>
            <label>Description</label>
            <Controller
              name="description"
              control={control}
              rules={{ required: 'Description is required' }}
              render={({ field }) => (
                <InputField
                  type="text"
                  placeholder="Description"
                  {...field}
                  error={errors.description ? errors.description.message : ''}
                  value={field.value}
                />
              )}
            />
          </div>
          <div className={styles.inputGroup}>
            <label>Minimal age</label>
            <Controller
              name="minAge"
              control={control}
              rules={{ required: 'Minimal age is required' }}
              render={({ field }) => (
                <InputField
                  type="number"
                  placeholder="Minimal age"
                  {...field}
                  error={errors.minAge?.message}
                  value={field.value}
                />
              )}
            />
          </div>
          <div className={styles.inputGroup}>
            <label>Price</label>
            <Controller
              name="price"
              control={control}
              rules={{ required: 'Price is required' }}
              render={({ field }) => (
                <InputField
                  type="number"
                  placeholder="Price"
                  {...field}
                  error={errors.price ? errors.price.message : ''}
                  value={field.value}
                />
              )}
            />
          </div>
          <div className={styles.inputGroup}>
            <label>Categories</label>
            <Controller
              name="categories"
              control={control}
              rules={{ required: 'At least one category is required' }}
              render={({ field, fieldState }) => (
                <CategorySelector
                  field={field}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </div>
          <div className={`${styles.inputGroup} ${styles.bookItem}`}>
            <label style={{ color: 'red' }}>
              <span>Deprecated: </span>
            </label>
            <input
              type="file"
              id="pdf-upload"
              accept="application/pdf"
              style={{ display: 'none' }}
            />
            <div>
              <MainButton
                disabled={true}
                label="Deprecated: /Upload PDF/"
                onClick={(event) => {
                  event.preventDefault();
                  document.getElementById('pdf-upload')?.click();
                }}
              />
            </div>
          </div>
          {/* {pdf && <PdfPreview file={pdf} />} */}
          <MainButton label="Save" type="submit" />
        </form>
      ) : (
        <div className={styles.mainContainer}>
          <h1 style={{ marginBottom: '30px' }}>{book.title}</h1>
          {book.author && (
            <h3 style={{ marginBottom: '30px' }}>by: {book.author}</h3>
          )}
          {book.description && <p>Description: {book.description}</p>}
          <p>Minimal age: {book.minAge}</p>
          <p>Price: {book.price}</p>
          {book.pdfUrl && !book.pdfUrl.includes('undefined') && (
            <p>
              <a href={book.pdfUrl} target="_blank" rel="noopener noreferrer">
                Download Book
              </a>
            </p>
          )}
          <p>
            Categories:{' '}
            {book.categories
              .map(
                (id) => categories.find((category) => category._id === id)?.name
              )
              .join(', ')}
          </p>
          <div className={styles.buttonsContainer}>
            <MainButton label="Edit" onClick={() => setEditMode(true)} />
            <MainButton label="Publish" onClick={() => handlePublish()} />
            <MainButton
              label="Delete"
              warning
              onClick={() => setModalOpen(true)}
            />
          </div>

          <div className={styles.pageTypeContainer}>
            <div className={styles.pageTypeContent}>
              {book?.pages?.map((page, index) => (
                <div key={index} className={styles.pageItem}>
                  <PageThumbnail
                    htmlContent={page.pageContent.html}
                    cssContent={page.pageContent.css}
                    pageNumber={page.pageNumber}
                    imageUrl={page.pageContent.images.mainImg}
                    width="150px"
                    height="250px"
                    onClick={() =>
                      handlePageClick(
                        page.pageContent.html,
                        page.pageContent.css,
                        page.width
                      )
                    }
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      <ResultModal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        title="Delete Book"
        confirmLabel="Delete"
        onConfirm={handleDelete}
      >
        <p>Do you really want to delete this book?</p>
      </ResultModal>
    </div>
  );
};
export default BookPage;
