import { FC, useRef, useEffect } from 'react';
import grapesjs, { Editor } from 'grapesjs';
import GjsEditor from '@grapesjs/react';
// import { savePage } from '../../store/slices/books';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { getSelectedBookPagesSelector } from '../../store/selectors/books';
import { BookPage } from '../../types/books';
import {
  savePageToServer,
  uploadBookPageImageToServer,
} from '../../store/thunks/books';
import { unwrapResult } from '@reduxjs/toolkit';
import './PageConstructor.module.css';

interface PageConstructorProps {
  onHide: () => void;
}

const PageConstructor: FC<PageConstructorProps> = ({ onHide }) => {
  const dispatch = useAppDispatch();
  const selectedBookPages = useAppSelector(getSelectedBookPagesSelector);

  const mainImgRef = useRef<string | null>(null);
  const scrollImgRef = useRef<string | null>(null);

  const selectedBook = useAppSelector((state) => state.books.selectedBook);

  // Устанавливаем изображения по ссылке при рендеринге готовой страницы
  useEffect(() => {
    const page = selectedBookPages.find((page) => page.pageNumber === 1); // Пример выбора страницы
    if (page && page.pageContent.images) {
      const mainImgElement = document.getElementById('main-img');
      const scrollImgElement = document.getElementById('scroll-img');

      if (mainImgElement && page.pageContent.images.mainImg) {
        mainImgElement.style.backgroundImage = `url(${page.pageContent.images.mainImg})`;
      }

      if (scrollImgElement && page.pageContent.images.scrollImg) {
        scrollImgElement.style.backgroundImage = `url(${page.pageContent.images.scrollImg})`;
      }
    }
  }, [selectedBookPages]);

  const determinePageType = (
    editor: Editor
  ): { type: BookPage['pageType']; width: number } => {
    const width =
      editor.getWrapper()?.view?.el.clientWidth ?? window.innerWidth;

    if (width >= 1024) {
      return { type: 'desktop', width };
    } else if (width >= 768) {
      return { type: 'tablet', width };
    } else if (width >= 576) {
      return { type: 'mobile-landscape', width };
    } else {
      return { type: 'mobile-portrait', width };
    }
  };

  // width: mobile portrait: 480px, mobile landscape = 568px, tablet = 770px

  const onEditor = (editor: Editor) => {
    console.log('Editor loaded', { editor });

    editor.Commands.add('save-to-store', {
      run: async (editor) => {
        const html = editor.getHtml();
        const css = editor.getCss();

        const textBlock = editor.getWrapper()?.find('p')[0];
        const text = textBlock ? textBlock.view?.el.innerText : '';

        if (!html.trim() && !css?.trim()) {
          alert('Cannot save an empty page.');
          return;
        }

        const pageType = determinePageType(editor);

        const pageNumberString = prompt('Enter the page number to save:');
        const pageNumber = pageNumberString
          ? parseInt(pageNumberString, 10)
          : null;

        if (pageNumber !== null && !isNaN(pageNumber)) {
          const pagesOfType =
            selectedBookPages.filter(
              (page) => page.pageType === pageType.type
            ) || [];

          if (pagesOfType.map((page) => page.pageNumber).includes(pageNumber)) {
            alert(
              `Page number ${pageNumber} already exists for ${pageType.type}. Please choose a different number.`
            );
            return;
          }

          if (!mainImgRef.current || !scrollImgRef.current) {
            alert('Both main and scroll images must be set before saving.');
            return;
          }

          const scrollBlock = editor.getWrapper()?.find('#scroll-img')[0];
          const scrollHeight = scrollBlock
            ? scrollBlock.view?.el.clientHeight
            : 0;

          if (!scrollHeight) {
            return;
          }

          // Формируем данные страницы
          const pageData = {
            pageNumber,
            pageContent: {
              html,
              css: css || '',
              images: {
                mainImg: mainImgRef.current,
                scrollImg: scrollImgRef.current || undefined,
              },
              text: text || '',
            },
            pageType: pageType.type,
            width: pageType.width,
            scrollHeight,
          };

          try {
            const bookId = selectedBook?._id; // Получаем идентификатор книги
            if (!bookId) {
              alert('Book ID is missing.');
              return;
            }

            await dispatch(savePageToServer({ bookId, pageData })).unwrap();

            alert(
              `Page number ${pageNumber} saved with type "${pageType.type}"`
            );

            onHide();
          } catch (error) {
            alert('Failed to save the page: ' + error);
          }
        } else {
          alert('Invalid page number.');
        }
      },
    });

    editor.Panels.addButton('options', {
      id: 'save-to-store',
      className: 'fa fa-save',
      command: 'save-to-store',
      attributes: {
        title: 'Save page to Redux Store',
      },
    });

    editor.BlockManager.add('image-background-block', {
      label: 'Fullscreen background image block',
      content: `
        <div id="main-img" style="height:100%; width:100%; position: absolute; background-size:cover; background-position:center;"></div>
      `,
      attributes: { class: 'gjs-fonts gjs-f-text' },
    });

    editor.BlockManager.add('bottom-attached-text-block', {
      label: 'Text at the bottom of the screen',
      content: `
        <div id="scroll-img" style="position:fixed; bottom:0; width:100%; height:200px; background-color:rgba(240, 240, 240, 0.1); overflow:auto; background-image: url(${selectedBook?.scrollImg}); background-size:cover; background-position:top; box-sizing: border-box; border-top: 1px solid #ccc; display: flex; justify-content: center; align-items: center; resize: horizontal;" class="gjs-f-butterfly">
          <div style="text-align:center; padding:10px; width:100%; overflow: auto;">
            <p style="font-size:16px; font-family:Playtime With Hot Toddies; color:#000; margin: auto">Enter your text here</p>
          </div>
        </div>
      `,
      attributes: { class: 'gjs-fonts gjs-f-text' },
    });

    editor.on('component:mount', (component) => {
      const el = component.getEl(); // Получаем элемент DOM после его рендера

      // Проверяем, является ли добавленный компонент блоком с id 'scroll-img'
      if (el && el.id === 'scroll-img') {
        scrollImgRef.current = selectedBook?.scrollImg?.toString() ?? null;
        if (scrollImgRef.current) {
          el.style.backgroundImage = `url(${scrollImgRef.current})`;
          console.log('scrollImgRef added: ', scrollImgRef.current);
        } else {
          console.log('No image available in scrollImgRef');
        }
      }
    });

    editor.StyleManager.addSector('typography', {
      name: 'Typography',
      open: true,
      buildProps: ['font-family', 'font-size', 'font-weight', 'color'],
      properties: [
        {
          property: 'font-family',
          name: 'Font',
          defaults: 'Arial, sans-serif',
          list: [
            {
              name: 'Playtime With Hot Toddies',
              value: 'Playtime With Hot Toddies',
              id: '1',
            },
            { name: 'Arial', value: 'Arial, sans-serif', id: '2' },
            { name: 'Georgia', value: 'Georgia, serif', id: '3' },
          ],
        },
      ],
    }); // editor.Commands.add('set-bg-image', {
    //   run: (editor, sender) => {
    //     sender.set('active', 0);
    //     const selected = editor.getSelected();

    //     if (!selected) {
    //       alert(
    //         'Выберите элемент, для которого хотите установить фоновое изображение.'
    //       );
    //       return;
    //     }

    //     const selectedId = selected.getAttributes().id;

    //     const fileInput = document.createElement('input');
    //     fileInput.type = 'file';
    //     fileInput.accept = 'image/*';
    //     fileInput.onchange = async (event: Event) => {
    //       const file = (event.target as HTMLInputElement).files?.[0];
    //       if (file) {
    //         try {
    //           // Отправляем файл на сервер и получаем URL изображения
    //           const imageUrl = await uploadBookPageImageToServer({
    //             file,
    //             title: 'title-of-image',
    //           }); // Здесь можете указать свой title
    //           console.log('Image URL:', imageUrl);

    //           // Применяем динамическую ссылку в зависимости от выбранного элемента
    //           selected.addStyle({ 'background-image': `url(${imageUrl})` });

    //           // Сохраняем ссылки на изображения в зависимости от элемента
    //           if (selectedId === 'main-img') {
    //             mainImgRef.current = imageUrl;
    //             console.log('Main image set:', imageUrl);
    //           } else if (selectedId === 'scroll-img') {
    //             scrollImgRef.current = imageUrl;
    //             console.log('Scroll image set:', imageUrl);
    //           }
    //         } catch (error) {
    //           console.error('Ошибка загрузки изображения:', error);
    //           alert('Не удалось загрузить изображение. Попробуйте снова.');
    //         }

    //         // if (selectedId === 'main-img') {
    //         //   const imageUrl =
    //         //     'http://localhost:4000/static/uploads/1/page-bg.jpg';
    //         //   selected.addStyle({ 'background-image': `url(${imageUrl})` });
    //         //   mainImgRef.current = imageUrl;
    //         //   console.log('Main image set:', imageUrl);
    //         // } else if (selectedId === 'scroll-img') {
    //         //   const imageUrl =
    //         //     'http://localhost:4000/static/uploads/1/scroll.png';
    //         //   selected.addStyle({ 'background-image': `url(${imageUrl})` });
    //         //   scrollImgRef.current = imageUrl;
    //         //   console.log('Scroll image set:', imageUrl);
    //         // }
    //       }
    //     };
    //     fileInput.click();
    //   },
    // });

    editor.StyleManager.addSector('text-styles', {
      name: 'Text Styles',
      open: true,
      buildProps: ['font-size', 'color', 'width', 'height', 'font-family'],
      properties: [
        { property: 'font-size', name: 'Font Size' },
        { property: 'color', name: 'Text Color' },
        { property: 'width', name: 'Width' },
        { property: 'height', name: 'Height' },
        {
          property: 'font-family',
          name: 'Font',
          value: 'Playtime With Hot Toddies',
          id: '1',
        },
      ],
    });

    editor.Commands.add('edit-bottom-block', {
      run: (editor, sender) => {
        sender.set('active', 0);
        const selected = editor.getSelected();

        if (!selected) {
          alert(
            'Select a block attached to the bottom of the screen to change its height.'
          );
          return;
        }

        const el = selected.getEl();
        if (!el?.classList.contains('gjs-f-butterfly')) {
          alert(
            'The selected element is not a block attached to the bottom of the screen.'
          );
          return;
        }

        const newHeight = prompt(
          'Enter the new block height (e.g., 300px):',
          //@ts-ignore
          selected.getStyle()['height']
        );

        if (newHeight) {
          selected.addStyle({ height: newHeight });
        }
      },
    });

    editor.Panels.addButton('options', {
      id: 'edit-bottom-block',
      className: 'fa fa-arrows-v',
      command: 'edit-bottom-block',
      attributes: {
        title:
          'Change the height of the block attached to the bottom of the screen',
      },
    });

    editor.Commands.add('set-bg-image', {
      run: (editor, sender) => {
        // Деактивируем кнопку или другой элемент интерфейса, если требуется
        sender.set('active', 0);

        // Получаем выбранный элемент в редакторе
        const selected = editor.getSelected();

        // Если элемент не выбран, выводим предупреждение
        if (!selected) {
          alert(
            'Выберите элемент, для которого хотите установить фоновое изображение.'
          );
          return;
        }

        // Получаем ID выбранного элемента
        const selectedId = selected.getAttributes().id;

        // Создаем элемент input для выбора файла
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = 'image/*'; // Принимаем только изображения

        // Обрабатываем изменение значения input, т.е. когда пользователь выбирает файл
        fileInput.onchange = async (event: Event) => {
          const file = (event.target as HTMLInputElement).files?.[0];
          if (file) {
            try {
              // Отправляем файл на сервер и получаем URL изображения
              const resultAction = await dispatch(
                uploadBookPageImageToServer({
                  file,
                  title: 'title-of-image', // Укажите title по необходимости
                })
              );

              // Используем unwrap, чтобы получить результат (URL изображения)
              const imageUrl = unwrapResult(resultAction);

              // Применяем полученную ссылку на фон элемента
              selected.addStyle({
                // 'background-image': `url("https://storage.googleapis.com/godly-kids/${imageUrl}")`,
                'background-image': `url("${process.env.REACT_APP_GC_URI}/${imageUrl}")`,

                // REACT_APP_GC_URI
              });

              // Логика для сохранения URL в зависимости от ID выбранного элемента
              if (selectedId === 'main-img') {
                mainImgRef.current = imageUrl;
                console.log('Main image set:', imageUrl);
              } else if (selectedId === 'scroll-img') {
                scrollImgRef.current = imageUrl;
                console.log('Scroll image set:', imageUrl);
              }
            } catch (error) {
              // Обрабатываем ошибки при загрузке изображения
              console.error('Image download is failed:', error);
              alert('Не удалось загрузить изображение. Попробуйте снова.');
            }
          }
        };

        // Имитируем клик по элементу input для открытия диалога выбора файла
        fileInput.click();
      },
    });

    editor.Panels.addButton('options', {
      id: 'set-bg-image',
      className: 'fa fa-image',
      command: 'set-bg-image',
      attributes: { title: 'Upload background image' },
    });
  };

  return (
    <div>
      <GjsEditor
        grapesjs={grapesjs}
        grapesjsCss="https://unpkg.com/grapesjs/dist/css/grapes.min.css"
        options={{
          height: '100vh',
          storageManager: false,
          fromElement: true,
        }}
        onEditor={onEditor}
      />
    </div>
  );
};

export default PageConstructor;
