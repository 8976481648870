import axios from "axios";
import { logout, setAccessToken, setRefreshToken } from "../slices/auth";
import store from "../store";
import { AxiosError } from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axiosInstance.interceptors.response.use(
  (response) => {
    console.log("✅ Ответ успешен:", response);
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    console.log("🚀 ~ originalRequest:", originalRequest);

    // Проверка на 401 ошибку и отсутствие повторной попытки
    if (error.response?.status === 401 && !originalRequest._retry) {
      console.log("🔄 401 ошибка. Попытка обновления токенов...");

      originalRequest._retry = true;

      try {
        const state = store.getState();
        const refreshToken = state.auth.refreshToken;

        if (refreshToken) {
          console.log(
            "🔑 Refresh token найден. Выполнение запроса на обновление токенов..."
          );

          // Выполняем запрос на обновление токенов
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/authentication/refresh-tokens`,
            { refreshToken }
          );

          const { accessToken, refreshToken: newRefreshToken } = response.data;

          if (accessToken && newRefreshToken) {
            console.log(
              "✅ Токены успешно обновлены. Сохранение новых токенов..."
            );

            // Обновляем токены в состоянии Redux
            store.dispatch(setAccessToken(accessToken));
            store.dispatch(setRefreshToken(newRefreshToken));

            // Устанавливаем новый токен доступа
            axiosInstance.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${accessToken}`;
            originalRequest.headers["Authorization"] = `Bearer ${accessToken}`;

            // Повторяем оригинальный запрос с новым токеном
            console.log("🔄 Повтор оригинального запроса с новым токеном...");
            return axiosInstance(originalRequest);
          } else {
            console.error("🚨 Новый accessToken или refreshToken не получен");
            store.dispatch(logout());
            return Promise.reject(error);
          }
        } else {
          console.error("🚨 Refresh token отсутствует. Выполнение logout...");
          store.dispatch(logout());
          return Promise.reject(error);
        }
      } catch (refreshError) {
        // Приведение refreshError к типу AxiosError для безопасной работы с его свойствами
        if (refreshError instanceof AxiosError) {
          // Проверяем статус ошибки обновления токена
          if (
            refreshError.response?.status === 401 ||
            refreshError.response?.status === 403
          ) {
            console.error("🚨 Неверный refreshToken. Выполнение logout...");
            store.dispatch(logout());

            // Устанавливаем _retry в false, чтобы избежать дальнейших повторов
            originalRequest._retry = false;
          } else {
            console.error("🚨 Ошибка при обновлении токенов:", refreshError);
          }
        } else {
          // Если ошибка не является типом AxiosError
          console.error("🚨 Неизвестная ошибка:", refreshError);
        }

        return Promise.reject(refreshError);
      }
    }

    console.error(
      "❌ Ошибка не связана с 401 статусом или повторной попыткой:",
      error
    );
    return Promise.reject(error);
  }
);

export default axiosInstance;
