import { FC, useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';

import InputField from '../../components/InputField/InputField';
import MainButton from '../../components/buttons/MainButton/MainButton';
import CategorySelector from '../../components/CategorySelector/CategorySelector';
import ImageUploader from '../../components/ImageUploader/ImageUploader';

import { uploadBuilderBook } from '../../store/thunks/books';
import { useAppDispatch } from '../../hooks/redux';
import { fetchCategories } from '../../store/thunks/categories';

import styles from './CreateNewBook.module.css';
import { UploadBookBuilderPayload } from '../../store/types';
import MiniButton from '../../components/buttons/MiniButton/MiniiButton';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import { IBook } from '../../types/books';

const CreateNewBook: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IBook>({
    defaultValues: {
      title: '',
      author: '',
      description: '',
      categories: [],
      cover: '',
      scrollImg: '',
      minAge: 0,
      price: 0,
    },
  });

  const [coverImage, setCoverImage] = useState<File | null>(null);

  const uploadCoverImage = async (file: File | null) => {
    setCoverImage(file);
  };

  const [scrollImage, setScrollImage] = useState<File | null>(null);
  const uploadScrollImg = async (file: File | null) => {
    //  console.log('uploadScrollImg');
    //  if (file) {
    //    const scrollImgURL = await dispatch(
    //      uploadBookPageImageToServer({
    //        file,
    //        title: file.name,
    //      })
    //    );
    //    const result = `https://storage.googleapis.com/godly-kids/${unwrapResult(
    //      scrollImgURL
    //    )}`;
    //    if (typeof result === 'string') {
    setScrollImage(file);
    //   setScrollImageUrl(result);
    //   return result;
    // }
    //  }
  };

  const onSubmit = async (data: IBook) => {
    const payload: UploadBookBuilderPayload = {
      data: {
        ...data,
      },
      files: {
        cover: coverImage,
        scrollImg: scrollImage,
      },
    };
    console.log('submit payload: ', payload);

    dispatch(uploadBuilderBook(payload)).then(() => {
      navigate('/dashboard/books');
    });
  };

  const backNavigate = () => {
    navigate(-1);
  };

  return (
    <div className={styles.bookUpload}>
      <h1>Create New Book</h1>
      <div className={styles.bookUpload__back}>
        <MiniButton onClick={backNavigate} secondary>
          <CloseIcon />
        </MiniButton>
      </div>
      <div className={styles.imagesContainer}>
        <div className={styles.imageContainer}>
          <h3>Choose cover image</h3>
          <ImageUploader
            image={coverImage}
            // imageUrl={coverImageUrl}
            imageUrl={null}
            onImageChange={uploadCoverImage}
            editMode
            inputId="coverImage"
          />
        </div>
        <div className={styles.imageContainer}>
          <h3>Choose scroll image</h3>
          <ImageUploader
            image={scrollImage}
            // imageUrl={scrollImageUrl}
            imageUrl={null}
            onImageChange={uploadScrollImg}
            editMode
            inputId="scrollImage"
          />
        </div>
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={styles.inputsContainer}
      >
        {/* Title */}
        <div className={styles.inputGroup}>
          <label>Title</label>
          <Controller
            name="title"
            control={control}
            rules={{ required: 'Title is required' }}
            render={({ field }) => (
              <InputField
                type="text"
                placeholder="Title"
                {...field}
                error={errors.title?.message}
              />
            )}
          />
        </div>
        {/* Author */}
        <div className={styles.inputGroup}>
          <label>Author</label>
          <Controller
            name="author"
            control={control}
            rules={{ required: 'Author is required' }}
            render={({ field }) => (
              <InputField
                type="text"
                placeholder="Author"
                {...field}
                error={errors.author?.message}
              />
            )}
          />
        </div>
        {/* Description */}
        <div className={styles.inputGroup}>
          <label>Description</label>
          <Controller
            name="description"
            control={control}
            rules={{ required: 'Description is required' }}
            render={({ field }) => (
              <InputField
                type="text"
                placeholder="Description"
                {...field}
                error={errors.description?.message}
              />
            )}
          />
        </div>
        {/* Categories */}
        <div className={styles.inputGroup}>
          <label>Categories</label>
          <Controller
            name="categories"
            control={control}
            render={({ field, fieldState }) => (
              <CategorySelector
                field={field}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </div>
        {/* MinAge */}
        <div className={styles.inputGroup}>
          <label>Minimal age</label>
          <Controller
            name="minAge"
            control={control}
            rules={{ required: 'Minimal age is required' }}
            render={({ field }) => (
              <InputField
                type="number"
                placeholder="Minimal age"
                {...field}
                error={errors.minAge?.message}
              />
            )}
          />
        </div>
        {/* Price */}
        <div className={styles.inputGroup}>
          <label>Price</label>
          <Controller
            name="price"
            control={control}
            rules={{ required: 'Price is required' }}
            render={({ field }) => (
              <InputField
                type="number"
                placeholder="Price"
                {...field}
                error={errors.price?.message}
              />
            )}
          />
        </div>
        <div className={styles.buttonContainer}>
          <MainButton label="Create a new book" type="submit" />
        </div>
      </form>
    </div>
  );
};
export default CreateNewBook;
