import { createAsyncThunk } from '@reduxjs/toolkit';

import { BookPage, IBook } from '../../types/books';
import { RootState } from '../store';

import axiosInstance from '../instance/axiosInstance';
import { setResult, setError, setLoading } from '../slices/app';
import { mapBookData } from '../../helpers/createBookData';
import { UploadBookBuilderPayload, UploadBookPayload } from '../types';

const booksResult = {
  success: {
    title: 'Success',
    content: `Book successfully uploaded`,
  },
  edit: {
    title: 'Edit',
    content: `Book successfully edited`,
  },
  delete: {
    title: 'Delete',
    content: `Book successfully deleted`,
  },
};

export const fetchBooks = createAsyncThunk<
  IBook[],
  undefined,
  { state: RootState }
>('books/fetchBooks', async (_, { dispatch, rejectWithValue }) => {
  dispatch(setLoading(true));
  try {
    const { data } = await axiosInstance.get<IBook[]>('/books');
    return data.map(mapBookData);
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || error.message;

    dispatch(setError(errorMessage));
    return rejectWithValue(errorMessage);
  } finally {
    dispatch(setLoading(false));
  }
});

export const uploadBook = createAsyncThunk<
  IBook,
  UploadBookPayload,
  { state: RootState }
>(
  'books/uploadBook',
  async ({ data, files }, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoading(true));

      const formData = new FormData();

      formData.append('title', data.title);
      formData.append('author', data.author);
      formData.append('minAge', data.minAge.toString());
      formData.append('price', data.price.toString());

      if (files.book) {
        formData.append('book', files.book);
      }
      if (files.cover) {
        formData.append('cover', files.cover);
      }

      if (data.categories) {
        formData.append('categories', data.categories.join(','));
      }

      const responce = await axiosInstance.post<IBook>('/books', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      dispatch(setResult(booksResult.success));

      return responce.data;
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || error.message;

      dispatch(setError(errorMessage));
      return rejectWithValue(errorMessage);
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const editBook = createAsyncThunk<
  IBook,
  UploadBookPayload,
  { state: RootState }
>('books/editBook', async (updatedBook, { dispatch, rejectWithValue }) => {
  dispatch(setLoading(true));
  const formData = new FormData();

  formData.append('title', updatedBook.data.title);
  formData.append('minAge', updatedBook.data.minAge.toString());
  formData.append('price', updatedBook.data.price.toString());
  formData.append('author', updatedBook.data.author);
  if (updatedBook.data.description) {
    formData.append('description', updatedBook.data.description);
  }

  if (updatedBook.data.categories) {
    formData.append('categories', updatedBook.data.categories.join(','));
  }

  if (updatedBook.files.cover) {
    formData.append('cover', updatedBook.files.cover);
  } else {
    console.log('Cover is missing');
  }

  if (updatedBook.files.scrollImg) {
    formData.append('scrollImg', updatedBook.files.scrollImg);
  }

  try {
    const response = await axiosInstance.patch<IBook>(
      `/books/${updatedBook.data._id}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    dispatch(setResult(booksResult.edit));
    dispatch(fetchBooks());

    return response.data;
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || error.message;

    dispatch(setError(errorMessage));
    return rejectWithValue(errorMessage);
  } finally {
    dispatch(setLoading(false));
  }
});

export const deleteBook = createAsyncThunk<
  string,
  string,
  { state: RootState }
>('books/deleteBook', async (bookId, { dispatch, rejectWithValue }) => {
  dispatch(setLoading(true));
  try {
    await axiosInstance.delete(`/books/${bookId}`);

    dispatch(setResult(booksResult.delete));
    dispatch(fetchBooks());
    return bookId;
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || error.message;

    dispatch(setError(errorMessage));
    return rejectWithValue(errorMessage);
  } finally {
    dispatch(setLoading(false));
  }
});

export const uploadBookPageImageToServer = createAsyncThunk<
  string,
  { file: File; title: string },
  { state: RootState }
>(
  'books/uploadBookPageImageToServer',
  async ({ file, title }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('title', title);
      const response = await axiosInstance.post(
        '/books/builder/upload-image',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue('Не удалось загрузить изображение на сервер');
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const uploadBuilderBook = createAsyncThunk<
  IBook,
  UploadBookBuilderPayload,
  { state: RootState }
>(
  'books/uploadBuilderBook',
  async ({ data, files }, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoading(true));

      const formData = new FormData();

      formData.append('title', data.title);
      formData.append('minAge', data.minAge.toString());
      formData.append('price', data.price.toString());
      formData.append('author', data.author);
      if (files.cover) {
        formData.append('cover', files.cover);
      }
      if (files.scrollImg) {
        formData.append('scrollImg', files.scrollImg);
      }
      if (data.description) {
        formData.append('description', data.description);
      }
      if (data.categories) {
        formData.append('categories', data.categories.join(','));
      }

      const response = await axiosInstance.post<IBook>('/books', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      dispatch(setResult(booksResult.success));

      return response.data;
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || error.message;

      dispatch(setError(errorMessage));
      return rejectWithValue(errorMessage);
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const savePageToServer = createAsyncThunk(
  'books/savePageToServer',
  async (
    { bookId, pageData }: { bookId: string; pageData: BookPage },
    { dispatch, rejectWithValue }
  ) => {
    dispatch(setLoading(true));
    try {
      const response = await axiosInstance.post('/books/builder/pages', {
        bookId,
        pageNumber: pageData.pageNumber,
        pageContent: {
          html: pageData.pageContent.html,
          css: pageData.pageContent.css,
          images: {
            mainImg: pageData.pageContent.images.mainImg,
            ...(pageData.pageContent.images.scrollImg && {
              scrollImg: pageData.pageContent.images.scrollImg,
            }),
          },
        },
        pageType: pageData.pageType,
        width: pageData.width,
        scrollHeight: pageData.scrollHeight,
      });

      dispatch(setResult(booksResult.success));
      return response.data;
    } catch (error: unknown) {
      if (error instanceof Error) {
        return rejectWithValue(
          (error as any).response?.data || 'Failed to save page'
        );
      }
      return rejectWithValue('Failed to save page');
    } finally {
      dispatch(setLoading(false));
    }
  }
);

// Fetch pages for a specific book
export const fetchBookPages = createAsyncThunk<BookPage[], string>(
  'books/fetchBookPages',
  async (bookId, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await axiosInstance.get(
        `/books/builder/pages/${bookId}`
      );
      return response.data; // Return the fetched pages
    } catch (error: unknown) {
      if (error instanceof Error) {
        return rejectWithValue(
          (error as any).response?.data || 'Failed to fetch pages'
        );
      }
      return rejectWithValue('Failed to fetch pages');
    } finally {
      dispatch(setLoading(false));
    }
  }
);

// Delete a specific page by ID
export const deletePage = createAsyncThunk<string, string>(
  'books/deletePage',
  async (pageId, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      await axiosInstance.delete(`/books/builder/pages/${pageId}`);
      dispatch(setResult(booksResult.delete));
      return pageId;
    } catch (error: unknown) {
      if (error instanceof Error) {
        return rejectWithValue(
          (error as any).response?.data || 'Failed to delete page'
        );
      }
      return rejectWithValue('Failed to delete page');
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const publishBook = createAsyncThunk<IBook, string>(
  'books/publishBook',
  async (bookId, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await axiosInstance.patch(
        `/books/builder/publish/${bookId}`
      );
      return response.data;
    } catch (error: unknown) {
      if (error instanceof Error) {
        return rejectWithValue(
          (error as any).response?.data || 'Failed to publish book'
        );
      }
      return rejectWithValue('Failed to publish book');
    } finally {
      dispatch(setLoading(false));
    }
  }
);
