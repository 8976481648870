import { FC, useState } from 'react';
import { Link } from 'react-router-dom';

import styles from './BookPreviewCard.module.css';

interface BookPreviewCardProps {
  _id: string;
  title: string;
  author?: string;
  imageUrl?: string;
  isSelector?: boolean;
  onClick?: () => void;
  isPublished?: boolean;
}

const BookPreviewCard: FC<BookPreviewCardProps> = ({
  _id,
  title,
  author,
  imageUrl,
  isSelector,
  onClick = () => null,
  isPublished,
}) => {
  const placeholderImage = '/image-not-found.webp';
  const [imgSrc, setImgSrc] = useState(imageUrl ? imageUrl : placeholderImage);

  const handleError = () => {
    setImgSrc(placeholderImage);
  };

  const cardContent = (
    <div className={styles.content}>
      <div className={styles.imageContainer}>
        <img
          className={styles.image}
          src={imgSrc}
          alt="Book"
          width={200}
          height={200}
          onError={handleError}
        />
      </div>
      <h2 className={styles.title}>{title}</h2>
      <h3 className={styles.author}>{author}</h3>
      <h3 className={styles.author}>
        {isPublished ? 'Published' : 'Not Published'}
      </h3>
    </div>
  );

  return (
    <div className={styles.card} onClick={onClick}>
      {isSelector ? (
        cardContent
      ) : (
        <Link className={styles.link} to={`/dashboard/books/${_id}`}>
          {cardContent}
        </Link>
      )}
    </div>
  );
};

export default BookPreviewCard;
