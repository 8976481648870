import React, { FC, useEffect } from 'react';

import BookPreviewCard from '../../components/BookPreviewCard/BookPrewiewCard';
import InputField from '../../components/InputField/InputField';

import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { searchBookResultSelector } from '../../store/selectors/books';
import { searchBooks } from '../../store/slices/books';
import { fetchBooks } from '../../store/thunks/books';
import {
  IBook,
  // IBuilderBook
} from '../../types/books';

import styles from './BooksPage.module.css';

const BooksPage: FC = () => {
  const dispatch = useAppDispatch();
  const books = useAppSelector(searchBookResultSelector);

  // console.log('books:', books);

  useEffect(() => {
    dispatch(fetchBooks());
  }, [dispatch]);

  const handleSearch = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = e.target.value;
    dispatch(searchBooks(value));
  };

  return (
    <div className={styles.booksContainer}>
      <div className={styles.searchContainer}>
        <InputField type="text" placeholder="Search" onChange={handleSearch} />
      </div>
      <h1 className={styles.header}>Book Previews</h1>
      <div className={styles.booksGrid}>
        {books.map((book: IBook) => (
          <BookPreviewCard key={book._id} {...book} />
        ))}
      </div>
    </div>
  );
};

export default BooksPage;
