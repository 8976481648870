import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BookPage, IBook } from '../../types/books';
import {
  deletePage,
  editBook,
  fetchBookPages,
  fetchBooks,
  publishBook,
  savePageToServer,
} from '../thunks/books';

interface BooksState {
  books: IBook[];
  searchResults: IBook[];
  selectedBook: IBook | null;
  selectedBookPages: BookPage[];
  images: { [key: string]: string }; // Хранение изображений по идентификатору страницы
}

const initialState: BooksState = {
  books: [],
  searchResults: [],
  selectedBook: null,
  selectedBookPages: [],
  images: {}, // Инициализируем объект для изображений
};

const booksSlice = createSlice({
  name: 'books',
  initialState,
  reducers: {
    searchBooks: (state, action: PayloadAction<string>) => {
      const query = action.payload.toLowerCase();

      state.searchResults = (state.books as IBook[]).filter((book) => {
        const hasTitle = book.title && typeof book.title === 'string';
        const hasAuthor = book.author && typeof book.author === 'string';

        if (hasTitle && book.title.toLowerCase().includes(query)) {
          return true;
        }

        if (hasAuthor && book.author.toLowerCase().includes(query)) {
          return true;
        }

        return false;
      }) as IBook[];
    },
    setSelectedBook: (state, action: PayloadAction<IBook | null>) => {
      state.selectedBook = action.payload;
    },
    setSelectedBookPages: (state, action: PayloadAction<BookPage[]>) => {
      state.selectedBookPages = action.payload;
    },
    savePage: (state, action: PayloadAction<BookPage>) => {
      state.selectedBookPages = [...state.selectedBookPages, action.payload];
    },
    uploadImage: (
      state,
      action: PayloadAction<{
        pageNumber: number;
        imageType: 'mainImg' | 'scrollImg';
        url: string;
      }>
    ) => {
      const { pageNumber, imageType, url } = action.payload;

      const page = state.selectedBookPages.find(
        (page) => page.pageNumber === pageNumber
      );

      if (page) {
        page.pageContent.images[imageType] = url;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchBooks.fulfilled,
        (state, action: PayloadAction<IBook[]>) => {
          state.books = action.payload;
          state.searchResults = action.payload;
        }
      )
      .addCase(editBook.fulfilled, (state, action: PayloadAction<IBook>) => {
        state.books = state.books.map((book) =>
          book._id === action.payload._id ? action.payload : book
        );
        state.searchResults = state.searchResults.map((book) =>
          book._id === action.payload._id ? action.payload : book
        );
      })
      .addCase(
        savePageToServer.fulfilled,
        (state, action: PayloadAction<BookPage>) => {
          state.selectedBookPages.push(action.payload);
        }
      )
      .addCase(
        fetchBookPages.fulfilled,
        (state, action: PayloadAction<BookPage[]>) => {
          state.selectedBookPages = action.payload;
        }
      )
      .addCase(deletePage.fulfilled, (state, action: PayloadAction<string>) => {
        state.selectedBookPages = state.selectedBookPages.filter(
          (page) => page._id !== action.payload
        );
      })
      .addCase(publishBook.fulfilled, (state, action: PayloadAction<IBook>) => {
        const bookIndex = state.books.findIndex(
          (book) => book._id === action.payload._id
        );
        if (bookIndex !== -1) {
          state.books[bookIndex] = action.payload;
        }
      });
  },
});

export const {
  searchBooks,
  setSelectedBook,
  setSelectedBookPages,
  savePage,
  uploadImage,
} = booksSlice.actions;
export default booksSlice.reducer;
