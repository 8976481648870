import React, { useRef, useEffect } from 'react';
import styles from './PageThumbnail.module.css';

interface PageThumbnailProps {
  htmlContent: string;
  cssContent: string;
  pageNumber: number;
  imageUrl?: string;
  width?: string;
  height?: string;
  onClick?: () => void;
}

const PageThumbnail: React.FC<PageThumbnailProps> = ({
  htmlContent,
  cssContent,
  pageNumber,
  imageUrl,
  width = '200px',
  height = '300px',
  onClick,
}) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    if (iframeRef.current && !imageUrl) {
      const document = iframeRef.current.contentDocument;
      if (document) {
        document.open();
        document.write(`
          <html>
            <head>
              <style>${cssContent}</style>
            </head>
            <body>${htmlContent}</body>
          </html>
        `);
        document.close();
      }
    }
  }, [htmlContent, cssContent, imageUrl]);

  return (
    <div
      onClick={onClick}
      style={{
        width,
        height,
        backgroundImage: imageUrl
          ? `url(https://api.godlykids.kbpublish.org/uploads/${imageUrl})`
          : 'none',
      }}
      className={styles.thumbnailContainer}
      title="Press to preview page"
    >
      <div className={styles.pageNumber}>{pageNumber}</div>
      {!imageUrl && (
        <iframe
          ref={iframeRef}
          title="Page Thumbnail"
          className={styles.thumbnailIframe}
        />
      )}
    </div>
  );
};
export default PageThumbnail;
