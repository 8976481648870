// import React, { FC, useState } from 'react';
// import { useAppDispatch, useAppSelector } from '../../hooks/redux';
// import {
//   booksSelector,
//   getSelectedBookPagesSelector,
//   getSelectedBookSelector,
// } from '../../store/selectors/books';
// import styles from './BookSelector.module.css';
// import BookPreviewCard from '../BookPreviewCard/BookPrewiewCard';
// import { IBook, BookPage } from '../../types/books';
// import {
//   setSelectedBook,
//   setSelectedBookPages,
// } from '../../store/slices/books';

// import CloseIcon from '@mui/icons-material/Close';
// import MainButton from '../buttons/MainButton/MainButton';
// import PageThumbnail from '../PageThumbnail/PageThumbnail';
// import MiniButton from '../buttons/MiniButton/MiniiButton';
// import { useNavigate } from 'react-router-dom';

// interface BookSelectorProps {
//   onShow: () => void;
// }

// const BookSelector: FC<BookSelectorProps> = ({ onShow }) => {
//   const dispatch = useAppDispatch();
//   const books = useAppSelector(booksSelector);
//   const selectedBook = useAppSelector(getSelectedBookSelector);
//   const selectedBookPages = useAppSelector(getSelectedBookPagesSelector);
//   const navigate = useNavigate();
//   console.log('🚀 ~ selectedBookPages:', selectedBookPages);

//   const [isTransitioning, setIsTransitioning] = useState(false);

//   const handleClick = (book: IBook) => {
//     setIsTransitioning(true);
//     setTimeout(() => {
//       dispatch(setSelectedBook(book));
//       setIsTransitioning(false);
//     }, 500);
//   };

//   const handleUnSelect = () => {
//     setIsTransitioning(true);
//     setTimeout(() => {
//       dispatch(setSelectedBook(null));
//       dispatch(setSelectedBookPages([]));
//       setIsTransitioning(false);
//     }, 500);
//   };

//   const handlePageClick = (
//     htmlContent: string,
//     cssContent: string,
//     width: number
//   ) => {
//     const newWindow = window.open('', '_blank', `width=${width},height=800`);
//     if (newWindow) {
//       const document = newWindow.document;
//       document.write(`
//         <html>
//           <head>
//             <style>${cssContent}</style>
//           </head>
//           <body>${htmlContent}</body>
//         </html>
//       `);
//       document.close();
//     }
//   };

//   const renderPagesByType = (type: string, pages: BookPage[]) => (
//     <div className={styles.pageTypeContainer}>
//       <h2 className={styles.pageTypeHeader}>{type}</h2>
//       <div className={styles.pageTypeContent}>
//         {pages.map((page, index) => (
//           <PageThumbnail
//             key={index}
//             htmlContent={page.pageContent.html}
//             cssContent={page.pageContent.css}
//             pageNumber={page.pageNumber}
//             imageUrl={page.pageContent.images.mainImg}
//             width="150px"
//             height="250px"
//             onClick={() =>
//               handlePageClick(
//                 page.pageContent.html,
//                 page.pageContent.css,
//                 page.width
//               )
//             }
//           />
//         ))}
//       </div>
//     </div>
//   );

//   const groupPagesByType = () => {
//     const groupedPages: Record<string, BookPage[]> = {
//       desktop: [],
//       tablet: [],
//       'mobile-landscape': [],
//       'mobile-portrait': [],
//     };

//     const clonedPagesArray = Array(15).fill([...selectedBookPages]);

//     clonedPagesArray.forEach((clonedPages) => {
//       clonedPages.forEach((page: any) => {
//         const type = page.pageType;
//         if (groupedPages[type]) {
//           groupedPages[type].push(page);
//         }
//       });
//     });

//     return groupedPages;
//   };

//   const groupedPages = groupPagesByType();

//   const navigateToCreateNewBook = () => {
//     navigate('/dashboard/create-new-book');
//   };

//   return (
//     <div
//       className={`${styles.booksContainer} ${
//         isTransitioning ? styles.hidden : ''
//       }`}
//     >
//       {selectedBook ? (
//         <>
//           <div className={styles.buttonsContainer}>
//             <MainButton label="Create a page" onClick={onShow} />
//             <MiniButton onClick={handleUnSelect} secondary>
//               <CloseIcon />
//             </MiniButton>
//           </div>
//           <h1 className={styles.header}>Selected book is:</h1>
//           <div className={styles.booksGrid}>
//             <BookPreviewCard {...selectedBook} isSelector />
//           </div>
//           <div className={styles.pagesContainer}>
//             {Object.keys(groupedPages).map((type) =>
//               groupedPages[type].length > 0 ? (
//                 <div key={type}>
//                   {renderPagesByType(type, groupedPages[type])}
//                 </div>
//               ) : null
//             )}
//           </div>
//         </>
//       ) : (
//         <>
//           <h1 className={styles.header}>Choose a book.</h1>
//           <div className={styles.buttonsContainer}>
//             <MainButton
//               label="Create a new book"
//               onClick={navigateToCreateNewBook}
//             />
//           </div>
//           <div className={styles.booksGrid}>
//             {books.map((book) => (
//               <BookPreviewCard
//                 key={book._id}
//                 {...book}
//                 isSelector
//                 onClick={() => handleClick(book)}
//               />
//             ))}
//           </div>
//         </>
//       )}
//     </div>
//   );
// };
// export default BookSelector;

import React, { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import {
  booksSelector,
  getSelectedBookPagesSelector,
  getSelectedBookSelector,
} from '../../store/selectors/books';
import styles from './BookSelector.module.css';
import BookPreviewCard from '../BookPreviewCard/BookPrewiewCard';
import { IBook, BookPage } from '../../types/books';
import {
  setSelectedBook,
  setSelectedBookPages,
} from '../../store/slices/books';
import CloseIcon from '@mui/icons-material/Close';
import MainButton from '../buttons/MainButton/MainButton';
import PageThumbnail from '../PageThumbnail/PageThumbnail';
import MiniButton from '../buttons/MiniButton/MiniiButton';
import { useNavigate } from 'react-router-dom';
import { deletePage, fetchBookPages } from '../../store/thunks/books'; // Import thunks for API calls

interface BookSelectorProps {
  onShow: () => void;
}

const BookSelector: FC<BookSelectorProps> = ({ onShow }) => {
  const dispatch = useAppDispatch();
  const books = useAppSelector(booksSelector);
  const selectedBook = useAppSelector(getSelectedBookSelector);
  const selectedBookPages = useAppSelector(getSelectedBookPagesSelector);
  const navigate = useNavigate();
  const [isTransitioning, setIsTransitioning] = useState(false);

  // Fetch pages when a book is selected
  useEffect(() => {
    if (selectedBook) {
      dispatch(fetchBookPages(selectedBook._id));
    }
  }, [selectedBook, dispatch]);

  const handleClick = (book: IBook) => {
    setIsTransitioning(true);
    setTimeout(() => {
      dispatch(setSelectedBook(book));
      setIsTransitioning(false);
    }, 500);
  };

  const handleUnSelect = () => {
    setIsTransitioning(true);
    setTimeout(() => {
      dispatch(setSelectedBook(null));
      dispatch(setSelectedBookPages([])); // Clear pages when unselecting a book
      setIsTransitioning(false);
    }, 500);
  };

  const handlePageClick = (
    htmlContent: string,
    cssContent: string,
    width: number
  ) => {
    const newWindow = window.open('', '_blank', `width=${width},height=800`);
    if (newWindow) {
      const document = newWindow.document;
      document.write(`
        <html>
          <head>
            <style>${cssContent}</style>
          </head>
          <body>${htmlContent}</body>
        </html>
      `);
      document.close();
    }
  };

  const handleDeletePage = (pageId: string) => {
    if (window.confirm('Are you sure you want to delete this page?')) {
      console.log('page Id to delete book selector: ', pageId);
      dispatch(deletePage(pageId)) // Dispatch action to delete the page
        .then(() => {
          alert('Page deleted successfully');
        })
        .catch((error: any) => {
          alert('Failed to delete the page: ' + error.message);
        });
    }
  };

  const renderPagesByType = (type: string, pages: BookPage[]) => (
    <div className={styles.pageTypeContainer}>
      <h2 className={styles.pageTypeHeader}>{type}</h2>
      <div className={styles.pageTypeContent}>
        {pages.map((page, index) => (
          <div key={index} className={styles.pageItem}>
            <PageThumbnail
              htmlContent={page.pageContent.html}
              cssContent={page.pageContent.css}
              pageNumber={page.pageNumber}
              imageUrl={page.pageContent.images.mainImg}
              width="150px"
              height="250px"
              onClick={() =>
                handlePageClick(
                  page.pageContent.html,
                  page.pageContent.css,
                  page.width
                )
              }
            />
            <MiniButton
              onClick={() => page._id && handleDeletePage(page._id)}
              secondary
            >
              Delete
            </MiniButton>
          </div>
        ))}
      </div>
    </div>
  );

  const groupPagesByType = () => {
    const groupedPages: Record<string, BookPage[]> = {
      desktop: [],
      tablet: [],
      'mobile-landscape': [],
      'mobile-portrait': [],
    };

    selectedBookPages?.forEach((page) => {
      const type = page.pageType;
      if (groupedPages[type]) {
        groupedPages[type].push(page);
      }
    });

    return groupedPages;
  };

  const groupedPages = groupPagesByType();

  const navigateToCreateNewBook = () => {
    navigate('/dashboard/create-new-book');
  };

  return (
    <div
      className={`${styles.booksContainer} ${
        isTransitioning ? styles.hidden : ''
      }`}
    >
      {selectedBook ? (
        <>
          <div className={styles.buttonsContainer}>
            <MainButton label="Create a page" onClick={onShow} />
            <MiniButton onClick={handleUnSelect} secondary>
              <CloseIcon />
            </MiniButton>
          </div>
          <h1 className={styles.header}>Selected book is:</h1>
          <div className={styles.booksGrid}>
            <BookPreviewCard {...selectedBook} isSelector />
          </div>
          <div className={styles.pagesContainer}>
            {Object.keys(groupedPages).map((type) =>
              groupedPages[type].length > 0 ? (
                <div key={type}>
                  {renderPagesByType(type, groupedPages[type])}
                </div>
              ) : null
            )}
          </div>
        </>
      ) : (
        <>
          <h1 className={styles.header}>Choose a book.</h1>
          <div className={styles.buttonsContainer}>
            <MainButton
              label="Create a new book"
              onClick={navigateToCreateNewBook}
            />
          </div>
          <div className={styles.booksGrid}>
            {books.map((book) => (
              <BookPreviewCard
                key={book._id}
                {...book}
                isSelector
                onClick={() => handleClick(book)}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default BookSelector;
